.privacy #page-header {
    padding-bottom: 0;
    margin: 0;
    border-bottom: none;
    background-color: #38393a;
    background-position: center;
    background-size: cover; /* Ensures the image covers the container */
    background-repeat: no-repeat;
    position: relative;
    min-height: 320px; /* Default height for large screens */
}

.privacy #page-header.overlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("https://media.istockphoto.com/id/1364971537/photo/privacy-policy-notice-and-legal-agreement.jpg?s=612x612&w=0&k=20&c=8IENM8HehuIOnYMH5uN4E1OKY7aU3qoRZ7OjeeHi0rI=");
    background-position: center;
    background-size: cover; /* Makes image responsive */
    background-color: rgba(58, 73, 90, 0.8); /* Adds overlay with opacity */
}

.privacy #page-header .container {
    height: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center; /* Center-align content vertically */
    justify-content: center; /* Center-align content horizontally */
}

.privacy #page-header .section-header {
    text-align: center;
    z-index: 1; /* Keeps content above the background */
}

.privacy #page-header .page-title {
    color: #fff;
    margin-bottom: 0;
    line-height: 1.4; /* Improves readability */
    font-weight: 550;
}

.privacy #page-header .page-title span {
    color: #9fbf51;
}

/* Media Queries for Different Screen Sizes */
@media (max-width: 1024px) {
    .privacy #page-header {
        min-height: 240px; /* Reduce height for smaller screens */
    }
}

@media (max-width: 768px) {
    .privacy #page-header {
        min-height: 200px;
        background-position: top; /* Adjust position for narrow viewports */
    }

    .privacy #page-header .page-title {
        font-size: 18px; /* Scale down font size */
    }
}
