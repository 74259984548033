@tailwind base;

html {
  scroll-behavior: smooth;
}
@tailwind components;
@tailwind utilities;



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-items {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out; /* Adjust the duration and timing function as needed */
}

.nav-items.open-menu {
  max-height: 100vh; /* Change to whatever height you want when the nav is open */
}
.nav-items.open-profile {
  max-height: 100vh; /* Change to whatever height you want when the nav is open */
}

.three-sides-box-shadow{
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2), 
                -3px 3px 5px rgba(0, 0, 0, 0.2),
                0px -3px 5px rgba(255, 255, 255, 1); /* This cancels out the top shadow */
}

.loading-spinner{
  background-color: rgba(255,255,255,0.7);
}