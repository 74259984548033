.faq-row-wrapper .faq-body .faq-row .row-title:hover .row-title-text{
    text-decoration: underline;
}
.faq-row-wrapper .faq-body {
    margin-top: 20px;
    margin-bottom: 20px;
}
.faq-row-wrapper .faq-body .faq-row{
    padding-inline: 20px;
    background-color: #f3f3fd;
    border-radius: 10px;
    margin-bottom: 15px;
}
.faq-row-wrapper .faq-body .faq-row .row-content .row-content-text{
    margin-bottom: 10px;
}

.dark .faq-row-wrapper .faq-body .faq-row {
    background-color: #3c3c3c;
    
}
.dark .faq-row-wrapper .faq-body .faq-row .row-title{
    color: white;
}
.dark .faq-row-wrapper .faq-body .faq-row .row-content .row-content-text {
    color: #e0e0e0;
}