/* home page buttom animation */
@import url('https://pro.fontawesome.com/releases/v6.0.0-beta1/css/all.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

:root {
  --glow-color: #a3a3a3;
  --dark-glow-color: #ffffff;
  --glow-color-text:#35353500;
  --dark-glow-color-text:#000000;
  --bg-color: #000000;
}



.home ul {
    width: min(100%, 60rem);
    overflow: hidden;
    margin-inline: auto;
    padding-inline: clamp(1rem, 5vw, 4rem);
    list-style: none;
    perspective: 1000px;
    display: grid;
    row-gap: 0.5rem;
}
.home ul li.card {
    position: relative;
    padding: 1.5rem 0.9rem 1.5rem 1.5rem;
    /* background-color: var(--bg-color); */
    background-image: linear-gradient(to right, rgb(0 0 0 / .15), transparent);
    transform-style: preserve-3d;
    /* color: var(--color); */
    display: flex;
    align-items: center;
    gap: 0;
}
.home ul li.card::before, .home ul li.card::after {
    --side-rotate: 60deg;
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    transform-origin: calc(50% - (50% * var(--ry))) 50%;
    transform: rotateY(calc(var(--side-rotate) * var(--ry)));
    background-color: inherit;
    background-image: linear-gradient(calc(90deg * var(--ry)), rgb(0 0 0 / .25), rgb(0 0 0 / .5));
}
.home ul li.card::before {--ry: -1; right: 100%}
.home ul li.card::after {--ry: 1; left: 100%}

.home ul li.card .icon {
    flex-shrink: 0;
    margin-right: 1rem;
}
.home ul li.card .text-content {
    display: flex;
    flex-direction: column;
}
.home ul li.card .social-title {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}
.home ul li.card .content {
    font-size: 1.1rem;
}

.home-section {
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.102);
    border-radius: 0 0 100% 0 / 0 0 5% 0;
}


.glowing-btn {
    position: relative;
    color: #373737;
    cursor: pointer;
    padding: 0.35em 1em;
    border: 0.15em solid var(--glow-color);
    border-radius: 0.45em;
    background: none;
    perspective: 2em;
    font-family: "Raleway", sans-serif;
    font-size: 2em;
    font-weight: 900;
    -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
      0px 0px 0.5em 0px var(--glow-color);
    -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
      0px 0px 0.5em 0px var(--glow-color);
    box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
      0px 0px 0.5em 0px var(--glow-color);
    animation: border-flicker 2s linear infinite;
  }
.dark .glowing-btn {
    position: relative;
    color: var(--dark-glow-color);
    cursor: pointer;
    padding: 0.35em 1em;
    border: 0.15em solid var(--dark-glow-color);
    border-radius: 0.45em;
    background: none;
    perspective: 2em;
    font-family: "Raleway", sans-serif;
    font-size: 2em;
    font-weight: 900;
    -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--dark-glow-color),
      0px 0px 0.5em 0px var(--glow-color);
    -moz-box-shadow: inset 0px 0px 0.5em 0px var(--dark-glow-color),
      0px 0px 0.5em 0px var(--glow-color);
    box-shadow: inset 0px 0px 0.5em 0px var(--dark-glow-color),
      0px 0px 0.5em 0px var(--dark-glow-color);
    animation: border-flicker 2s linear infinite;
  }
  
  .glowing-txt {
    float: left;
    margin-right: -0.8em;
    -webkit-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3)
      0 0 0.45em var(--glow-color);
    -moz-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
      0 0 0.45em var(--glow-color);
    text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
    animation: text-flicker 3s linear infinite;
  }
  .dark .glowing-txt {
    float: left;
    margin-right: -0.8em;
    -webkit-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3)
      0 0 0.45em var(--glow-color);
    -moz-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
      0 0 0.45em var(--glow-color);
    text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--dark-glow-color);
    animation: text-flicker 3s linear infinite;
  }
  
  .faulty-letter {
    opacity: 0.5;
    animation: faulty-flicker 2s linear infinite;
  }
  
  .glowing-btn::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.7;
    filter: blur(1em);
    transform: translateY(120%) rotateX(95deg) scale(1, 0.35);
    background: var(--glow-color);
    pointer-events: none;
  }
  .dark .glowing-btn::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.7;
    filter: blur(1em);
    transform: translateY(120%) rotateX(95deg) scale(1, 0.35);
    background: var(--dark-glow-color);
    pointer-events: none;
  }
  
  .glowing-btn::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: -1;
    background-color: var(--glow-color);
    box-shadow: 0 0 2em 0.2em var(--glow-color);
    transition: opacity 100ms linear;
  }
  .dark .glowing-btn::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: -1;
    background-color: var(--dark-glow-color);
    box-shadow: 0 0 2em 0.2em var(--dark-glow-color);
    transition: opacity 100ms linear;
  }
  
  .glowing-btn:hover {
    color: rgba(255, 255, 255, 0.8);
    text-shadow: none;
    animation: none;
  }
  .dark .glowing-btn:hover {
    color: rgb(70, 70, 70);
    text-shadow: none;
    animation: none;
  }
  
  .glowing-btn:hover .glowing-txt {
    animation: none;
  }
  
  .glowing-btn:hover .faulty-letter {
    animation: none;
    text-shadow: none;
    opacity: 1;
  }
  
  .glowing-btn:hover:before {
    filter: blur(1.5em);
    opacity: 1;
  }
  
  .glowing-btn:hover:after {
    opacity: 1;
  }
  
  @keyframes faulty-flicker {
    0% {
      opacity: 0.1;
    }
    2% {
      opacity: 0.1;
    }
    4% {
      opacity: 0.5;
    }
    19% {
      opacity: 0.5;
    }
    21% {
      opacity: 0.1;
    }
    23% {
      opacity: 1;
    }
    80% {
      opacity: 0.5;
    }
    83% {
      opacity: 0.4;
    }
  
    87% {
      opacity: 1;
    }
  }
  
  @keyframes text-flicker {
    0% {
      opacity: 0.1;
    }
  
    2% {
      opacity: 1;
    }
  
    8% {
      opacity: 0.1;
    }
  
    9% {
      opacity: 1;
    }
  
    12% {
      opacity: 0.1;
    }
    20% {
      opacity: 1;
    }
    25% {
      opacity: 0.3;
    }
    30% {
      opacity: 1;
    }
  
    70% {
      opacity: 0.7;
    }
    72% {
      opacity: 0.2;
    }
  
    77% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  
  @keyframes border-flicker {
    0% {
      opacity: 0.1;
    }
    2% {
      opacity: 1;
    }
    4% {
      opacity: 0.1;
    }
  
    8% {
      opacity: 1;
    }
    70% {
      opacity: 0.7;
    }
    100% {
      opacity: 1;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .glowing-btn{
      font-size: 1em;
    }
    .home ul li.card::before, .home ul li.card::after {
      --side-rotate: 76deg;
  }
  }
  


@media only screen and (max-width: 600px) {
    .glowing-btn {
        font-size: 1.5em;
        padding: 0.35em 0.5em;
    }
    .dark .glowing-btn {
        font-size: 1.5em;
        padding: 0.35em 0.5em;
    }
}
.arrow:hover .arrow-icon {
    transform: translateX(10px); /* Adjust the value as needed */
    scale: 1.1;
}
.home-section {
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.102);
    border-radius: 0 0 100% 0 / 0 0 5% 0;
}
