

body {
    overflow-x: hidden;
}

nav {
    height: 80px;
    width: 100vw;
    background: hsla(0, 0%, 100%, .005);
    backdrop-filter: blur(8px);
    display: flex;
    position: fixed;
    z-index: 10;

}


nav h1 {
    margin-bottom: 0px;
}




/*Styling Links*/
.nav-links {
    display: flex;
    list-style: none;
    width: auto;
    justify-content: space-evenly;
    align-items: center;
    text-transform: uppercase;
    margin-bottom: 0px;
}

.nav-links li {
    position: relative; /* Ensure li is positioned relative to contain ::before */
}

.nav-links li p {
    position: relative; /* Ensure p is positioned relative to contain ::before */
    text-decoration: none; /* Remove default underline for links */
    margin: 0 0.7vw; /* Adjust spacing as needed */
    color: #3e3e3e; /* Text color */
    user-select: none; /* Prevent text selection */
    padding-bottom: 5px; /* Add padding to make space for underline */
}

.nav-links li p::before {
    content: "";
    display: block;
    height: 2.5px;
    width: 0%; /* Initial width of the underline */
    background-color: #d0d0d066; /* Color of the underline */
    position: absolute;
    bottom: 0; /* Position the underline at the bottom of p */
    left: 0; /* Align underline from the left */
    transition: all ease-in-out 300ms; /* Smooth transition for width change */
}

.nav-links li p:hover {
    color: #909195; /* Change text color on hover */
}



.nav-links li p:hover::before {
    width: 100%; /* Expand underline width on hover */
}


/*Styling Buttons*/
.nav-right-margin {
    margin-right: 30px !important;
}

.nav-left-margin {
    margin-left: 30px !important;
}

.login-button {
    background-color: transparent;
    border: 1.5px solid #f2f5f7;
    border-radius: 2em;
    padding: 0.6rem 0.8rem;
    margin-left: 2vw;
    font-size: 1rem;
    cursor: pointer;

}

.login-button:hover {
    color: #131418;
    background-color: #f2f5f7;
    border: 1.5px solid #f2f5f7;
    transition: all ease-in-out 350ms;
}

.join-button {
    color: #131418;
    background-color: #ffffff;
    border: 1.5px solid #ffffff;
    border-radius: 2em;
    padding: 0.6rem 0.8rem;
    font-size: 1rem;
    cursor: pointer;
}

.join-button:hover {
    color: #f2f5f7;
    background-color: transparent;
    border: 1.5px solid #f2f5f7;
    transition: all ease-in-out 350ms;
}

.dropdown-content::-webkit-scrollbar {
    display: none;
}



/*Styling Hamburger Icon*/
.hamburger div {
    width: 30px;
    height: 3px;
    background: #c0c0c0;
    margin: 5px;
    transition: all 0.3s ease;
}

.hamburger {
    display: none;
}

/*Stying for small screens*/
@media screen and (max-width: 1024px) {
    .nav-links li p::before {
        background-color: #9c9c9c; /* Color of the underline */
    }
    
    .nav-links li p:hover {
        color: #909195; /* Change text color on hover */
    }
    .nav-links li p {
        font-size: larger;
    }
    .hamburger {
        display: block;
        
    }
    nav .row {
        width: 50% !important;
    }

    .fade:not(.show) {
        opacity: 1 !important;
    }

    .nav-links {
        padding-left: 0px;
    }

    .nav-right-margin {
        margin-right: 50px !important;
    }

    nav {
        position: fixed;
        z-index: 3;
    }

    .hamburger {
        display: block;
        position: absolute;
        cursor: pointer;
        right: 2%;
        top: 50%;
        transform: translate(-5%, -50%);
        z-index: 2;
        transition: all 0.7s ease;
    }

    

    .nav-links.open {
        clip-path: circle(1000px at 108% 2%);
        -webkit-clip-path: circle(1000px at 90% -10%);
        pointer-events: all;
    }

   

    .nav-links li:nth-child(1) {
        transition: all 0.5s ease 0.2s;
    }

    .nav-links li:nth-child(2) {
        transition: all 0.5s ease 0.4s;
    }

    .nav-links li:nth-child(3) {
        transition: all 0.5s ease 0.6s;
    }

    .nav-links li:nth-child(4) {
        transition: all 0.5s ease 0.7s;
    }

    .nav-links li:nth-child(5) {
        transition: all 0.5s ease 0.8s;
    }

    .nav-links li:nth-child(6) {
        transition: all 0.5s ease 0.9s;
        margin: 0;
    }

    .nav-links li:nth-child(7) {
        transition: all 0.5s ease 1s;
        margin: 0;
    }

    li.fade {
        opacity: 1;
    }

    .nav-left-margin {
        margin-left: 20px !important;
    }
    .nav-links {
        position: fixed;
        height: 100vh;
        width: 100%;
        flex-direction: column;
        clip-path: circle(50px at 90% -20%);
        -webkit-clip-path: circle(50px at 90% -10%);
        transition: all 1s ease-out;
        pointer-events: none;
    }
    .nav-links li {
        opacity: 0;
    }
}


@media(max-width:500px) {
    .nav-left-margin {
        margin-left: 10px !important;
    }
    .nav-links.open {
        -webkit-clip-path: circle(880px at 150% 6%);
        pointer-events: all;
    }
    
}


/*Animating Hamburger Icon on Click*/
.toggle .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
    transition: all 0.7s ease;
    width: 0;
}

.toggle .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
}