* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.display-none{
    display: none;
}

.marginDown {
    margin-bottom: 10px;
    text-decoration: none;
}

.marginDown:hover {
    text-decoration: underline;
}

/* button on signup */
.signup-with-google-btn {
    background-color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: .5rem;
    color: rgb(29, 28, 28);
    border: none;
    transition: all 0.7s ease-in-out;
    border: 2px solid gray;
}


.signup-with-google-btn :hover {
    /* background-color: rgb(0, 0, 0); */
    /* color: rgb(255, 255, 255); */
}

.signup-with-google-btn i {
    margin-right: 10px;
}

.outer-box {
    width: 100vw;
    /* background: linear-gradient(to bottom right, #545454, #030303); */
}

.spotify-nav-login {
    background-color: #000000;
    height: 10vh;
    gap: 20px;
}

.back-arrow-in-login {
    transform: scale(1.5);
    margin-left: 40px;

}

.spotify-name-in-loginpage {
    width: 20px;
}

.black-logo {
    width: 50px;
}

.inner-box {
    width: 509px;
    padding: 20px 40px;
    /* background-color: #ffffffcc; */
    backdrop-filter: blur(5px);
    border-radius: 8px;
}
.signup-body input{
    border: 1px solid #555;
}





.signup-header h1 {
    font-size: 2rem;
    font-weight: 600;
    color: #000000;

}


.signup-body {
    margin: 40px 0 0 0;

}



.h-90{
    height: 90%;
}




.create-account {
    color: #ffffff;
    font-weight: 700;
    cursor: pointer;
}

.password-mismatch {
    color: red;
    margin-top: 10px;
}

.show-and-hide{
    margin-left: 3px;
}


/* sign in btn */
.outer-box #sign-in-btn ,
.outer-box #reset-btn{
    background-color: #4b4b4b;
    margin: 10px 0;
    border: none;
    transition: transform 0.2s ease-in-out;
    transition: all .3s ease;
    width: 95%;
}

.outer-box #sign-in-btn:hover,
.outer-box #reset-btn:hover {
    translate: -4px -4px;
    box-shadow: 4px 4px 0 #bababa;
}

.outer-box .btn-click {
    translate: 4px 4px !important;
    box-shadow: none !important;
}
.row .btn-click {
    translate: 4px 4px !important;
    box-shadow: none !important;
}




/* signup btn */
.outer-box #sign-up-btn {
    background-color: #4b4b4b;
    margin: 10px 0;
    border: none;
    transition: transform 0.2s ease-in-out;
    transition: all .3s ease;
    width: 95%;
    height: 55px !important;
}

.outer-box #sign-up-btn:hover {
    translate: -4px -4px;
    box-shadow: 4px 4px 0 #bababa;
}

/* verify otp */
.outer-box #verify-otp-btn {
    background-color: #4b4b4b;
    margin: 10px 0;
    transition: all .3s ease;
    width: 95%;
    border-radius: 5px;
    padding: 7px;
    font-size: 1.2rem;
    color: #ffffff;
    font-weight: 700;
    height: 50px;
    text-align: center;
}

.outer-box #verify-otp-btn:hover {
    translate: -4px -4px;
    box-shadow: 4px 4px 0 #bababa;
}


.shake-button {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
}

@keyframes shake {

    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.signup-footer {
    margin-top: 15px;
}

.signup-footer p a{
    color: #0091ff;
}
.signup-footer p a:hover{
    text-decoration: underline;
}



.message-box {
    margin-top: 10px;
}

.message-box p {
    display: inline;
}

.inner-box {
    box-shadow: 3px 3px 5px;
}

#signup-page .signup-body {
    margin: 0 0 0 0;
}

#signup-page .signup-body p {
    margin: 5px 0;
}

/* verify otp styling */

form .otp {
    display: inline-block;
    width: 50px;
    height: 50px;
    text-align: center;
    border: 1px solid black;
    border-radius: 4px;
}

.bgWhite {
    background: transparent;
}

.title {
    font-weight: 600;
    margin-top: 0px;
    font-size: 24px;
    color: #aaaaaa;
    margin-bottom: 20px;
}

.otp-holders {
    gap: 10px;
}

.customBtn {
    border-radius: 0px;
    padding: 10px;
}

form input {
    height: 45px;
}

/* forgot password page */
.footer p a{
    color: #0091ff;
}
.row {
    color: #000000;
    text-align: center;
    padding: 0em 0em 0em;
    width: 100%;
    margin: 0em auto;
    border-radius: 5px;
}

.row h1 {
    font-size: 2.5em;
}

.row .form-group {
    margin: 0.5em 0;
}

.row .form-group label {
    display: block;
    text-align: left;
    font-weight: 600;
}

.row .form-group input {
    display: block;
    width: 100%;
    /* border: none; */
    border: 1px solid #555;
    padding: 1em 0.5em;
    border-radius: 5px;
    font-weight: 400;
    font-size: 1em;

}

.row .form-group input:focus {
    background-color: #fff;
    color: #000;
    border: none;
    animation: pulse 1s infinite ease;
    font-weight: 400;
    font-size: 1em;
}


.row .footer h5 {
    margin-top: 1em;
}




@media screen and (max-width: 700px) {
    .inner-box{
        width: 450px !important;
    }
    .signup-header h1 {
        font-size: 1.5rem;
    }
    .signup-body {
        margin: 20px 0 0 0;
    }

    .back-arrow-in-login {
        transform: scale(1.3);
        margin-left: 22px;
    }
    .black-logo {
        width: 40px;
    }
    .spotify-name-in-loginpage {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 550px){
   .inner-box{
    padding: 20px 20px;
   }
   .inner-box{
        width: 400px !important;
    }
    .signup-header h1 {
        font-size: 1.8rem;
    }
    .signup-body {
        margin: 15px 0 0 0;
    }
    .forgotpassword {
       
        font-size: 16px;
    }
    .back-arrow-in-login {
        transform: scale(1.3);
        margin-left: 22px;
    }
    .black-logo {
        width: 40px;
    }
    .spotify-name-in-loginpage {
        font-size: 1.5rem;
    }
    
    form input {
        height: 43px;
    }
    .create-account {
        font-weight: 600;
    }
    .row h1 {
        font-size: 2.0em;
    }
    .row .form-group input {
        font-size: 1.1em;
    }
}

@media screen and (max-width: 380px){
    .spotify-nav-login {
       
        gap: 3px;
    }
   .inner-box{
    padding: 10px 10px;
   }
   .inner-box{
        width: 300px !important;
    }
    .signup-header h1 {
        font-size: 1.5rem;
    }
    .signup-body {
        margin: 15px 0 0 0;
    }
  
    .forgotpassword {
       
        font-size: 16px;
    }
    .back-arrow-in-login {
        transform: scale(1.1);
        margin-left: 10px;
    }
    .black-logo {
        width: 40px;
    }
    .spotify-name-in-loginpage {
        font-size: 1.5rem;
    }
    
    form input {
        height: 43px;
    }
    .create-account {
        font-weight: 600;
    }
}


@media screen and (max-width: 320px) {
    .row {
        padding-left: 1em;
        padding-right: 1em;
    }

    .row h1 {
        font-size: 1.5em !important;
    }
}